import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import modules from "./modules";

Vue.use(Vuex);

const plugins = [];

if (process.env.NODE_ENV !== "production") {
  plugins.push(createLogger({}));
}

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins
});
