var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"wrapper",attrs:{"id":"header"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"row header-container"},[_c('div',{staticClass:"header-container__logo"},[_c('a',{staticClass:"logo",attrs:{"href":_vm.settings.link_main_website_href}},[_c('img',{attrs:{"src":require("../assets/images/Loop-Fund-by-Fox-Lending.png"),"height":"65","alt":"Loop Fund"}})])]),(!_vm.status.error)?_c('div',{staticClass:"header-container__steps"},[_c('div',{staticClass:"steps-container"},[_c('span',{class:{
                stepCounter: true,
                stepActive: _vm.$route.name === 'step-1',
                stepComplete: (_vm.$route.name === 'step-2' || _vm.$route.name === 'step-3'),
                stepInactive: false,
              }},[_vm._m(0)]),_c('span',{class:{
                stepCounter: true,
                stepActive: _vm.$route.name === 'step-2',
                stepComplete: _vm.$route.name === 'step-3',
                stepInactive: _vm.$route.name === 'step-1',
              }},[_vm._m(1)]),_c('span',{class:{
                stepCounter: true,
                stepActive: _vm.$route.name === 'step-3',
                stepComplete: false,
                stepInactive: (_vm.$route.name === 'step-1' || _vm.$route.name === 'step-2'),
              }},[_vm._m(2)])])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"plain"},[_c('li',[_c('span',{staticClass:"label"},[_vm._v("Step 1")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"plain"},[_c('li',[_c('span',{staticClass:"label"},[_vm._v("Step 2")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"plain"},[_c('li',[_c('span',{staticClass:"label"},[_vm._v("Step 3")])])])
}]

export { render, staticRenderFns }