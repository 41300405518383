import Vue from "vue";
import pick from "lodash/pick";

async function getBrandSettings() {
  if (process.env.VUE_APP_CA_SETTING_URL === undefined) {
    return {};
  }

  const brandSettingKeys = [
    "company_long_name",
    "company_phone",
    "company_email",
    "link_terms_of_use_href",
    "link_terms_of_use_label",
    "link_esign_consent_href",
    "link_esign_consent_label",
    "link_privacy_policy_href",
    "link_privacy_policy_label",
    "sms_terms",
    "link_sms_terms_href",
    "link_sms_terms_label",
    "link_main_website_href",
  ];
  const settings = await Vue.prototype.$axios.get(process.env.VUE_APP_CA_SETTING_URL);

  return pick(settings.data, brandSettingKeys);
}

const initialState = {
  settings: {},
  apiUrl: "",
  apiKey: "",
  apiCampaignId: "",
  campaignInfo: null,
  version: process.env.VUE_APP_VERSION,
};

const actions = {
  async getSystemSettings({ commit }) {
    const settings = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_UNDERWRITING_URL}api/v1/customer/settings/CustomerPortal`
    );

    commit("SET_SETTINGS", { ...settings.data, ...(await getBrandSettings()) });
  },

  setUrl({ commit }, url) {
    commit("SET_URL", url);
  },

  setApiKey({ commit }, apiKey) {
    commit("SET_API_KEY", apiKey);
  },

  setCampaignId({ commit }, campaignId) {
    commit("SET_CAMPAIGN_ID", campaignId);
  },

  checkLendingStates({ state }, currentState) {
    if (state.settings.non_lending_state.includes(currentState)) {
      const redirectUrl = state.settings?.link_non_lending_states_href || "";

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }
};

const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  SET_URL(state, url) {
    state.apiUrl = url;
  },

  SET_API_KEY(state, apiKey) {
    state.apiKey = apiKey;
  },

  SET_CAMPAIGN_ID(state, campaignId) {
    state.apiCampaignId = campaignId;
  },

  setCampaignInfo(state, campaignInfo) {
    state.campaignInfo = campaignInfo;
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
