import LoanApplication from "../../LoanApplication";

const initialState = {
  loan: {
    requestamount: "",
    state: "",
    loan_purpose: "",
    firstname: "",
    lastname: "",
    email: "",
    homephone: "",
    address: "",
    address1: "",
    city: "",
    zip: "",
    rentorown: "",
    addressyears: "",
    addressmonths: "",
    dlstate: "",
    dlnumber: "",
    ssn: "",
    dob: "",
    iscitizen: "",
    ismilitary: "false",
    hiredate: "",
    empname: "",
    empphone: "",
    empstate: "",
    empzip: "",
    incometype: "",
    paytype: "",
    payfrequency: "",
    lastpaydate: "",
    nextpaydate: "",
    grossmonthly: "",
    bankname: "",
    accounttype: "",
    routingnumber: "",
    accountnumber: "",
    how_receive_payment: "",
    how_long_work_here: "",
    empyears: "",
    empmonths: "",
    empposition: "",
    customer_guid: null,
    last_loan_info: {},
    user_from: null,
    user_from_id: null,
    consent_status: 0,
    allow_credit_check: false,
    term_condition: false,
    usa_patriot_act: false,
    sms_program_terms: "false",
    prospectId: null,
    walkup_guid: null,
    settlementReapplyInfo: {
      customerGuid: null,
      displayId: null
    }
  },
  status: {
    error: false,
    declined: false,
    loading: false,
    processing: false,
    isReturningCustomer: false,
    isLendingTreeCustomer: false,
    serverErrorMessage: null,
    errorPageRoute: null,
  },
  applicationAccepted: false,
  prospect: {},
  leadProviderCampaignProductType: null,
  loanInputToPageMap: {},
};

const actions = {
  setLoan({ commit }, loan) {
    commit("SET_LOAN", loan);
  },

  updateLoan({ commit }, loanStepData) {
    commit("UPDATE_LOAN", loanStepData);
  },

  setError({ commit }, error) {
    commit("SET_ERROR", error);
  },

  setDeclined({ commit }, declined) {
    commit("SET_DECLINED", declined);
  },

  setProcessing({ commit }, processing) {
    commit("SET_PROCESSING", processing);
  },

  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },

  setIsReturningCustomer({ commit }, isReturningCustomer) {
    commit("SET_IS_RETURNING_CUSTOMER", isReturningCustomer);
  },

  setIsLendingTreeCustomer({ commit }, isLendingTreeCustomer) {
    commit("SET_IS_LENDING_TREE_CUSTOMER", isLendingTreeCustomer);
  },

  setProspect({ commit }, prospect) {
    commit("SET_PROSPECT", prospect);
  },

  setLeadProviderCampaignProductType({ commit }, leadProviderCampaignProductType) {
    commit("SET_LEAD_PROVIDER_CAMPAIGN_PRODUCT_TYPE", leadProviderCampaignProductType);
  },

  setSettlementReapplyCustomerGuid({ commit }, customerGuid) {
    commit("SET_SETTLEMENT_REAPPLY_CUSTOMER_GUID", customerGuid);
  },

  setSettlementReapplyDisplayId({ commit }, displayId) {
    commit("SET_SETTLEMENT_REAPPLY_DISPLAY_ID", displayId);
  },

  async submitApplication({ commit, state, rootState }) {
    commit("UPDATE_STATUS", {
      error: false,
      declined: false,
      processing: true,
      errorPageRoute: null,
      serverErrorMessage: null,
    });

    const loanApp = new LoanApplication(
      state.loan,
      rootState.settings,
      state.status.isReturningCustomer,
      state.status.isLendingTreeCustomer
    );
    const result = await loanApp.submit();

    if (result.validationMessage) {
      const errorPageRoute = Object.keys(state.loanInputToPageMap).find((route) => {
        const routeInputs = state.loanInputToPageMap[route];
        return routeInputs.includes(result.validationKey);
      });

      commit("UPDATE_STATUS", {
        errorPageRoute: errorPageRoute || null,
        serverErrorMessage: result.validationMessage,
      });
      commit("SET_PROCESSING", false);
    } else if (result.declined) {
      commit("SET_DECLINED", true);
      commit("SET_PROCESSING", false);
    } else if (result.error) {
      commit("SET_ERROR", true);
      commit("SET_PROCESSING", false);
    } else {
      commit("setApplicationAccepted", true);
    }
  },
};

const mutations = {
  SET_LOAN(state, loan) {
    state.loan = { ...state.loan, ...loan };
  },

  UPDATE_LOAN(state, loanStepData) {
    Object.entries(loanStepData).forEach(([property, value]) => {
      if (state.loan[property] !== value) {
        state.loan[property] = value;
      }
    });
  },

  UPDATE_STATUS(state, status) {
    state.status = { ...state.status, ...status };
  },

  SET_ERROR(state, error) {
    state.status.error = error;
  },

  SET_DECLINED(state, declined) {
    state.status.declined = declined;
  },

  SET_PROCESSING(state, processing) {
    state.status.processing = processing;
  },

  SET_LOADING(state, loading) {
    state.status.loading = loading;
  },

  SET_IS_RETURNING_CUSTOMER(state, isReturningCustomer) {
    state.status.isReturningCustomer = isReturningCustomer;
  },

  SET_IS_LENDING_TREE_CUSTOMER(state, isLendingTreeCustomer) {
    state.status.isLendingTreeCustomer = isLendingTreeCustomer;
  },

  SET_PROSPECT(state, prospect) {
    state.prospect = prospect;
  },

  SET_LEAD_PROVIDER_CAMPAIGN_PRODUCT_TYPE(state, leadProviderCampaignProductType) {
    state.leadProviderCampaignProductType = leadProviderCampaignProductType;
  },

  SET_SETTLEMENT_REAPPLY_CUSTOMER_GUID(state, customerGuid) {
    state.loan.settlementReapplyInfo.customerGuid = customerGuid;
  },

  SET_SETTLEMENT_REAPPLY_DISPLAY_ID(state, displayId) {
    state.loan.settlementReapplyInfo.displayId = displayId;
  },

  registerPageInputs(state, { route, inputs }) {
    state.loanInputToPageMap[route] = inputs;
  },

  setApplicationAccepted(state, applicationAccepted) {
    state.applicationAccepted = applicationAccepted;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
