import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// Initialize VueRouter with base configuration
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

// Fetch and add brand-specific routes to the router
(async () => {
  const activeBrand = await import(`./${process.env.VUE_APP_CUSTOMER_APP_INSTANCE_ID}/routes`);
  const brandRoutes = activeBrand.default || [];

  brandRoutes.forEach((route) => router.addRoute(route));
})();

export default router;
