<template>
  <div>
    <input
      ref="ssnInput"
      v-model="maskValue"
      type="text"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      @click="cursorToTheEnd()"
      @blur="checkSSNNumber()"
      @focus="handleFocus()"
      @keyup.delete="removeAll()"
      @keyup.backspace="removeAll()"
    >
    <div
      v-if="hasError"
      class="help-block with-errors"
    >
      <span class="error">
        <ul class="icons">
          <li>
            <span class="icon fa-exclamation-circle">
              <span class="label">
                <i v-if="validator.required === false">Required</i>
                <i v-else-if="validator.min === false">Invalid SSN number</i>
              </span>
            </span>
          </li>
        </ul>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SSNInput",

  props: {
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validator: {
      required: false,
      type: Object,
      default() {
        return {
          min: false
        };
      }
    }
  },

  data() {
    return {
      maskValue: "",
      tempValue: "",
    };
  },

  computed: {
    hasError() {
      if (!this.validator) {
        return false;
      }

      return this.validator.$error && this.validator.$dirty;
    }
  },

  watch: {
    value(newValue) {
      this.maskValue = newValue;
      this.transformFormat();
      this.updateTemp();
      this.checkSSNNumber();
    },

    maskValue(value) {
      if (value && !value.includes("*")) {
        this.onlyNumbers();
        this.transformFormat();
        this.updateTemp();
      }
    },
  },

  mounted() {
    this.maskValue = this.value;
    this.onlyNumbers();
    this.transformFormat();
  },

  methods: {
    onlyNumbers() {
      if (this.maskValue) {
        this.maskValue = this.maskValue.replace(/[^0-9|\\*]/g, "");
        this.maskValue = this.maskValue.slice(0, 9);
      }
    },

    transformFormat() {
      if (this.maskValue && this.maskValue.length >= 4) {
        this.maskValue = `${this.maskValue.slice(0, 3)}-${this.maskValue.slice(3)}`;
      }

      if (this.maskValue && this.maskValue.length >= 7) {
        this.maskValue = `${this.maskValue.slice(0, 6)}-${this.maskValue.slice(6)}`;
      }
    },

    updateTemp() {
      this.tempValue = this.maskValue.replace(/-/g, "");
    },

    cursorToTheEnd() {
      if (this.maskValue) {
        this.$refs.ssnInput.setSelectionRange(this.maskValue.length, this.maskValue.length);
      }
    },

    checkSSNNumber() {
      this.maskValue = this.maskValue.replace(/[0-9]/g, "*");
      this.$emit("input", this.tempValue);
      this.validator.$touch();
    },

    removeAll() {
      this.maskValue = "";
      this.tempValue = "";
      this.$emit("input", this.tempValue);
    },

    handleFocus() {
      this.validator.$reset();
    }
  }
};
</script>

<style scoped>

</style>
