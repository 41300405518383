import { DateTime } from "luxon-business-days";

const qaLead = {};

if (process.env.VUE_APP_QA_FEATURE_ENABLED === "true") {
  const local = DateTime.local();
  const hiredate = local.plusBusiness({ days: -90 });
  const nextpaydate = local.plusBusiness({ days: 7 });
  const lastpaydate = local.plusBusiness({ days: -7 });

  const ipaddress = `${local.weekday}.${local.month}.${local.day}.${local.minute}`;

  qaLead.data = {
    api_key: "29d49a-fce07b-93ff0e-1bf4f7-8a4c51",
    ipaddress,
    affid: "11111111",
    campaign: "3",
    test: "",
    testtype: "",
    loan_purpose: "Other",
    requestamount: "1000",
    ssn: "767011143",
    dob: "1999-05-13",
    firstname: "Henry",
    lastname: "Ssassafras",
    address: "6948 Shepherd",
    address2: "",
    city: "Russelville",
    state: "DE",
    zip: "19702",
    homephone: "(305)211-1111",
    dlstate: "AR",
    dlnumber: Math.floor(Math.random() * 1000000),
    ismilitary: "false",
    rentorown: "O",
    email: `test+${Date.now()}@user.com`,
    addressmonths: 2,
    addressyears: 1,
    iscitizen: "true",
    otheroffers: "false",
    incometype: "O",
    paytype: "D",
    hiredate: hiredate.toFormat("yyyy-MM-dd"),
    empmonths: "",
    empyears: "",
    empname: "Test Company",
    empphone: "(222)222-2222",
    empstate: "MO",
    empzip: "63106",
    empphoneext: "",
    payfrequency: "W",
    lastpaydate: lastpaydate.toFormat("yyyy-MM-dd"),
    nextpaydate: nextpaydate.toFormat("yyyy-MM-dd"),
    netmonthly: "3500",
    grossmonthly: "3500",
    bankname: "Test Bank",
    accounttype: "C",
    routingnumber: "256074974",
    accountnumber: `${Math.floor(Math.random() * 100000000)}`,
    bankmonths: "0",
    bankyears: "4",
    clarityResponse: {
      xml_response: {
        inquiry: {
          ofac_match: "",
          social_security_deceased: "",
          social_security_valid: "true",
          bank_routing_valid: "true",
          number_of_ssns_with_bank_account: "1"
        },
        clear_fraud_insight: {
          score: "800"
        },
        clear_bureau_lite: {
          supplier_bureau_lite: {
            income_insight_w2_score: "630",
            vantage4_score: "630",
            fico9_score: "100"
          }
        },
        clear_fraud: {
          clear_fraud_score: "100",
          non_scorable_reason_code: "100",
          fraud_signature_match: "100",
          clear_fraud_indicator: {
            best_on_file_ssn_issue_date_cannot_be_verified: "100",
            inquiry_age_younger_than_ssn_issue_date: "100",
            credit_established_prior_to_ssn_issue_date: "100"
          }
        },
        clear_credit_risk: {
          score: "580",
          number_of_loans_past_due: "0",
          active_duty_status: "0",
          number_of_loans_current_and_open: "0",
          days_since_last_loan_charged_off: "",
          worst_payment_rating: "0"
        },
        clear_bank_behavior: {
          cbb_score: "710",
          cbb_score2: "710",
          accounts: {
            reason_codes: "0"
          }
        }
      }
    },
    validifiResponse: {
      Response: {
        Requests: {
          Request: {
            Packages: {
              Package: {
                Scorings: {
                  Scoring: {
                    Score: "115"
                  }
                }
              }
            }
          }
        }
      }
    },
    whitepagesResponse: {
      identity_check_score: "115",
      ip_address_checks: {
        geolocation: {
          country_code: "US"
        }
      },
      identity_network_score: "115"
    }
  };
}

export default qaLead;
