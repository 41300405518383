<template>
  <div v-if="isReturningCustomer">
    <h3>
      If any information has changed, you can update here. If you have no changes, you can can just click the next
      button at the bottom to proceed.
    </h3>
  </div>
  <div v-else>
    <h3 class="highlightColor">
      It really is this easy.
    </h3>
    <ol>
      <li>Complete the entire process online.</li>
      <li>Get a decision instantly.</li>
      <li>Receive your money within one business day.<sup>*</sup></li>
    </ol>
    <h3 class="highlightColor">
      Safe and Secure
    </h3>
    <p>
      We use advanced bank-level encryption to ensure your information is confidential and secure at all times.
    </p>
    <p>
      <img
        src="../assets/images/SSL-Secure.png"
        width="240"
        alt="SSL Secure Encryption"
        border="0"
      >
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isReturningCustomer: {
      type: Boolean,
      default: false
    }
  }
};
</script>
