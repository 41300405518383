<template>
  <div id="wrapper">
    <environment-banner />
    <HeadTitle :title="`Personal Loan Application - ${settings.company_long_name}`" />

    <Header :status="status" />

    <Banner
      v-if="showBanner"
      :is-returning-customer="status.isReturningCustomer"
    />

    <section
      id="main"
      class="wrapper"
    >
      <div class="inner">
        <div class="content mt-s">
          <!-- Processing -->
          <Processing v-if="status.processing" />
          <!-- ./Processing -->

          <!-- Loading -->
          <Loading v-else-if="status.loading" />
          <!-- ./Loading -->

          <!-- Forms -->
          <div
            v-else
            class="row"
          >
            <div class="col-8 col-12-medium rightpadding">
              <router-view />
            </div>

            <div
              v-if="!status.error || !status.declined"
              class="col-4 col-4-large leftborder helpertext"
            >
              <RightAside :is-returning-customer="status.isReturningCustomer" />
            </div>
          </div>
          <!-- ./Forms -->
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import Header from "@app/loop/components/Header.vue";
import Footer from "@app/loop/components/Footer.vue";
import Banner from "@app/loop/components/Banner.vue";
import RightAside from "@app/loop/components/RightAside.vue";
import FormGroup from "@app/loop/components/FormGroup.vue";

import Loading from "./components/Loading.vue";
import Processing from "./components/Processing.vue";

import "@/assets/css/spacing.css";
import "@app/loop/assets/css/main.css";

// this is specific to loop for now
Vue.component("FormGroup", FormGroup);

export default {
  components: {
    Header,
    Footer,
    Banner,
    RightAside,
    Loading,
    Processing
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("settings", ["settings"]),
    ...mapState("underwriting", ["status", "leadProviderCampaignProductType"]),
    showBanner() {
      if (this.$route.name === "error" || this.$route.name === "declined") {
        return false;
      }

      return this.$route.name === "step-1" && (!this.status.loading && !this.status.processing);
    }
  },

  watch: {
    "status.declined": {
      deep: true,
      handler(value) {
        if (value) {
          this.$router.push("/declined");
        }
      }
    },

    "status.error": {
      deep: true,
      handler(value) {
        if (value) {
          this.$router.push("/error");
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.v-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

#wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-flow: column;

  #footer {
    flex-grow: 1; // this make sure footer take remaining space
  }
}
</style>
