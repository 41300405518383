<template>
  <section id="banner">
    <div
      v-if="isReturningCustomer"
      class="inner"
    >
      <h1>New Loan Request</h1>
      <p>
        To process your new loan request, we need to verify the information you provided on your most recent
        application.
      </p>
    </div>
    <div
      v-else
      class="inner"
    >
      <h1>Let's Find your Loan</h1>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isReturningCustomer: {
      type: Boolean,
      default: false
    }
  }
};
</script>
