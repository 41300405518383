import Vue from "vue";
import Vuelidate from "vuelidate";
import VueGtm from "vue-gtm";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import axios from "axios";
import TextInput from "@/components/ui/TextInput.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import SSNInput from "@/components/ui/SSNInput.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import Inputmask from "@/helpers/inputmask";
import PrivateLabelLoader from "@/config/PrivateLabelLoader";
import EnvironmentBanner from "@/plugins/environment-banner/environment-banner";
import HeadTitle from "@/components/HeadTitle.vue";
import AppWrapper from "./AppWrapper.vue";
import store from "./store";
import router from "./router";

import "flatpickr/dist/flatpickr.css";

Bugsnag.start({
  apiKey: process.env.VUE_APP_WALKUP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.VUE_APP_VERSION,
  onError(event) {
    event.addMetadata("brand", { name: process.env.VUE_APP_HEAD_TITLE });
  },
});
Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);

Vue.component("HeadTitle", HeadTitle);
Vue.component("TextInput", TextInput);
Vue.component("NumberInput", NumberInput);
Vue.component("SSNInput", SSNInput);
Vue.component("DatePicker", DatePicker);
Vue.directive("inputmask", Inputmask);
Vue.use(Vuelidate);

Vue.use(PrivateLabelLoader);
Vue.use(VueGtm, {
  enabled: !!process.env.VUE_APP_GTM_ID, // GTM scripts are only injected if the gtm_id exists
  id: process.env.VUE_APP_GTM_ID,
  debug: false, // console.logs every action
  ignoredViews: [],
});
Vue.use(EnvironmentBanner);

Vue.prototype.$axios = axios.create({ withCredentials: true });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(AppWrapper),
}).$mount("#app");
