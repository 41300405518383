<template>
  <header
    id="header"
    class="wrapper"
  >
    <div class="inner">
      <div class="content">
        <div class="row header-container">
          <div class="header-container__logo">
            <a
              class="logo"
              :href="settings.link_main_website_href"
            ><img
              src="../assets/images/Loop-Fund-by-Fox-Lending.png"
              height="65"
              alt="Loop Fund"
            ></a>
          </div>
          <div
            v-if="!status.error"
            class="header-container__steps"
          >
            <div class="steps-container">
              <span
                :class="{
                  stepCounter: true,
                  stepActive: $route.name === 'step-1',
                  stepComplete: ($route.name === 'step-2' || $route.name === 'step-3'),
                  stepInactive: false,
                }"
              >
                <ul class="plain">
                  <li><span class="label">Step 1</span></li>
                </ul>
              </span>
              <span
                :class="{
                  stepCounter: true,
                  stepActive: $route.name === 'step-2',
                  stepComplete: $route.name === 'step-3',
                  stepInactive: $route.name === 'step-1',
                }"
              >
                <ul class="plain">
                  <li><span class="label">Step 2</span></li>
                </ul>
              </span>
              <span
                :class="{
                  stepCounter: true,
                  stepActive: $route.name === 'step-3',
                  stepComplete: false,
                  stepInactive: ($route.name === 'step-1' || $route.name === 'step-2'),
                }"
              >
                <ul class="plain">
                  <li><span class="label">Step 3</span></li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState("settings", ["settings"]),
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  justify-content: space-between;
}

.steps-container {
  display: flex;
}
</style>
