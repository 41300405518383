import { DateTime } from "luxon";
import Vue from "vue";

export default class LoanApplication {
  errorsMaps = {
    requestamount: "Requested amount is not valid. Please enter a valid amount.",
    ssn: "Social Security Number should only contain digits and be 9 characters long.",
    dob: "Date of Birth is invalid. Please enter a valid date in YYYY-MM-DD format.",
    firstname: "First Name cannot be empty. Please enter a valid First Name.",
    lastname: "Last Name cannot be empty. Please enter a valid Last Name.",
    address: "Address cannot be empty. Please enter a valid Address.",
    city: "City cannot be empty. Please enter a valid City name.",
    state: "State should be a valid 2 character abbreviation.",
    zip: "Zip Code is not valid. Please enter a valid 5 digit Zip Code.",
    homephone: "Home Phone is invalid. Please enter a valid Home Phone number in (XXX)XXX-XXXX format.",
    dlstate: "Driver License State should be a valid 2 character abbreviation.",
    dlnumber: "Driver License Number should be at least 5 characters long.",
    addressmonths: "Address Months should be a one, two or three digit number.",
    addressyears: "Address Years should be a one or two digit number.",
    rentorown: "Housing Status is invalid. please select a valid option.",
    ismilitary: "Military Status is invalid. Please select a valid option.",
    iscitizen: "Citizenship Status is invalid. Please select a valid option.",
    email: "Email is invalid. Please enter a valid email address.",
    incometype: "Income Type is invalid. Please select a valid option.",
    paytype: "Pay Type is invalid. Please select a valid option.",
    empmonths: "Employment Months should be a one, two or three digit number.",
    empyears: "Employment Years should be a one or two digit number.",
    empname: "Employer Name cannot be empty. Please enter a valid Employer Name.",
    empphone: "Employer Phone is invalid. Please enter a valid Employer Phone number in (XXX)XXX-XXXX format.",
    hiredate: "Hire Date is invalid. Please enter a valid date in YYYY-MM-DD format.",
    payfrequency: "Pay Frequency is invalid. Please select a valid option.",
    netmonthly: "Net Monthly Income is not valid. Please enter a valid amount.",
    grossmonthly: "Gross Monthly Income is not valid. Please enter a valid amount.",
    lastpaydate: "Last Pay Date is invalid. Please enter a valid date in YYYY-MM-DD format.",
    secondpaydate: "Second Pay Date is invalid. Please enter a valid date in YYYY-MM-DD format.",
    bankname: "Bank Name cannot be empty. Please enter a valid Bank Name.",
    accounttype: "Account Type is invalid. Please select a valid option.",
    // eslint-disable-next-line max-len
    routingnumber: "Routing Number must be associated with a valid bank, should only contain digits and cannot be empty.",
    accountnumber: "Account Number should only contain digits and cannot be empty.",
  };

  constructor(loanData, settings, returnCustomer, lendingTreeCustomer) {
    this.returnCustomer = returnCustomer;
    this.lendingTreeCustomer = lendingTreeCustomer;

    this.settings = settings.settings;
    this.apiUrl = settings.apiUrl;
    this.apiKey = settings.apiKey;
    this.apiCampaignId = settings.apiCampaignId;
    this.campaignInfo = settings.campaignInfo;

    this.loanData = loanData;
  }

  handleLastPayDate() {
    const { nextpaydate, payfrequency } = this.loanData;

    this.consoleLog("handleLastPayDate", nextpaydate, payfrequency);

    const frequencyDays = {
      W: 7,
      B: 14,
      S: 15,
      M: 30,
    };

    const days = frequencyDays[payfrequency] || 7;
    return DateTime.fromISO(nextpaydate).minus({ days }).toISODate();
  }

  calculateEmploymentData() {
    const { hiredate, empmonths, empyears } = this.loanData;

    if (hiredate) {
      const hireDate = DateTime.fromISO(hiredate);
      const employedDuration = DateTime.local().diff(hireDate, ["months", "years"]).toObject();

      return {
        hiredate,
        empmonths: empmonths || Math.round(employedDuration.months),
        empyears: empyears || Math.round(employedDuration.years),
      };
    }

    if (empyears || empmonths) {
      const currentDate = DateTime.local();
      const calculatedHireDate = currentDate.minus({ months: empmonths || 0, years: empyears || 0 }).toISODate();

      return {
        empmonths,
        empyears,
        hiredate: calculatedHireDate,
      };
    }

    const defaultHireDate = DateTime.now().toISODate();

    return {
      empmonths: 1,
      empyears: 0,
      hiredate: defaultHireDate,
    };
  }

  dateIncByFrequency() {
    const { nextpaydate, payfrequency } = this.loanData;
    const date = DateTime.fromISO(nextpaydate);

    const frequencyIncrements = {
      W: { weeks: 1 },
      B: { weeks: 2 },
      S: { days: 15 },
      M: { months: 1 },
    };

    const increment = frequencyIncrements[payfrequency] || { weeks: 1 };

    this.consoleLog("dateIncByFrequency", nextpaydate, payfrequency, increment);

    return date.plus(increment).toISODate();
  }

  checkIsNonLendingState() {
    const { state } = this.loanData;
    const nonLendingStates = this.settings?.non_lending_state || [];

    this.consoleLog("checkIsNonLendingState", state, nonLendingStates);

    if (nonLendingStates.length > 0) {
      if (nonLendingStates.includes(state) && this.settings?.link_non_lending_states_href) {
        window.location.assign(this.settings.link_non_lending_states_href);
      }
    }
  }

  checkIsMilitary() {
    const { ismilitary } = this.loanData;
    const mlaLink = this.settings?.link_mla_href || null;

    this.consoleLog("checkIsMilitary", ismilitary, mlaLink);

    if (ismilitary === "true" && mlaLink) {
      window.location.assign(mlaLink);
    }
  }

  pushGtmDataLayer(reference, redirect) {
    this.consoleLog("pushGtmDataLayer", reference, redirect);
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      email: this.loanData.email || "",
      cust_id: this.loanData?.customer_guid || "",
      lead_id: reference,
      lead_guid: redirect.substring(
        redirect.lastIndexOf("/") + 1
      ),
    });
  }

  buildPayload() {
    this.consoleLog("buildPayload");

    this.checkIsMilitary();
    this.checkIsNonLendingState();

    let payload = {
      ...this.loanData,
      empphone: this.loanData.empphone.replace(/\s+/g, ""),
      homephone: this.loanData.homephone.replace(/\s+/g, ""),
      otheroffers: "false",
      affid: "8",
      subid: "22",
      testtype: 2,
      netmonthly: this.loanData.grossmonthly,
      consent_status: this.loanData.consent_status ? 1 : 0,
      // allow_credit_check,
      api_key: this.apiKey,
      campaign: this.apiCampaignId,
      ...this.calculateEmploymentData(),
      secondpaydate: this.dateIncByFrequency(),
      lastpaydate: this.handleLastPayDate(),
      return_customer: this.returnCustomer ? 1 : 0,
      walkup_version: process.env.VUE_APP_VERSION,
    };

    if (this.lendingTreeCustomer) {
      payload = {
        ...payload,
        user_from: "lendingtree",
        user_from_id: this.loanData.user_from_id,
      };
    }

    if (this.campaignInfo) {
      payload = { ...payload, ...this.campaignInfo };
    }

    let walkupGuid = JSON.parse(localStorage.getItem("walkup-tracking"));
    walkupGuid = walkupGuid?.walkup_guid || null;

    if (walkupGuid) {
      payload = {
        ...payload,
        walkup_guid: walkupGuid,
      };
    }

    this.consoleLog("buildPayload payload", payload);

    return payload;
  }

  async submit() {
    this.consoleLog("submit", this.apiUrl);
    let error = false;
    let declined = false;

    let validationKey = "";
    let validationMessage = "";

    try {
      const payload = this.buildPayload();
      const { status, data } = await Vue.prototype.$axios.post(`${this.apiUrl}api`, payload);
      this.consoleLog("submitApplication response", status, data);

      if (status === 200) {
        localStorage.removeItem("loanData");

        if (data.result === "lead_accepted") {
          this.pushGtmDataLayer(data.reference, data.redirect);
          window.location.assign(data.redirect);
        } else {
          declined = true;
        }
      }
    } catch (e) {
      if (e.response.status === 422) {
        [validationKey] = `${e.response.data.message}`.split(" ");
        validationMessage = this.errorsMaps[validationKey];
      } else {
        error = true;
      }
    }

    return {
      error,
      declined,
      validationKey,
      validationMessage,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  consoleLog(message, ...args) {
    if (process.env.NODE_ENV === "development") {
      console.log("LoanApplication", message, ...args);
    }
  }
}
