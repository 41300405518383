<template>
  <div>
    <App v-if="isLeadProviderActive && leadProviderCampaignProductType" />
    <Maintenance v-else-if="!isLeadProviderActive" />
    <Loader v-else />
    <Popup
      :show="status.serverErrorMessage"
      @close="status.serverErrorMessage = null"
    >
      <template #header>
        <h3>Data Validation Error</h3>
      </template>
      <div class="row">
        <div class="col-sm-12">
          <p>
            We could not process your application due to a data validation error: <br>
            <strong>{{ status.serverErrorMessage }}</strong>
            <br>
            Please update the information and try again.
          </p>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Bugsnag from "@bugsnag/js";
import qaLead from "./qaLead";
import Maintenance from "./Maintenance.vue";
import Loader from "../components/ui/Loader.vue";
import Popup from "../components/ui/Popup.vue";

const LENDINGTREE_FROM_STRING = "lendingtree";

export default {
  components: {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    App: require(`@app/${process.env.VUE_APP_CUSTOMER_APP_INSTANCE_ID}/App.vue`).default,
    Maintenance,
    Loader,
    Popup,
  },
  data() {
    return {
      baseApiUrl: process.env.VUE_APP_UNDERWRITING_URL,
      recaptchaVerified: false,
      isLeadProviderActive: true,
      isSettlementReapply: false
    };
  },

  computed: {
    ...mapState("underwriting", ["loan", "prospect", "status", "leadProviderCampaignProductType"]),
    ...mapState("settings", ["settings", "apiUrl", "apiKey", "apiCampaignId"]),
    isPrescreenProspect() {
      return this.prospect && Object.keys(this.prospect).length;
    },
  },

  watch: {
    "status.errorPageRoute": {
      handler() {
        if (this.status.errorPageRoute) {
          this.$router.push(this.status.errorPageRoute);
        }
      },
    },
    status: {
      deep: true,
      handler() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
    },
  },

  async created() {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    const dataUrlB64 = params.get("dataUrlB64");

    await this.getSystemSettings();

    if (dataUrlB64) {
      const dataUrl = atob(dataUrlB64);
      await this.setReturningAppData(dataUrl);
    }

    // Checking that settlement Reapply exist.
    this.checkSettlementReapply(params.get("settlementReapply") && params.get("oldDisplayId"));
    // status.isReturningCustomer is used inside initLeadSettings
    // so this needs to be called after setReturningAppData
    this.initLeadSettings();

    this.checkActiveApplication(params.get("activeOfferRedirect"));
    this.setCampaign(params);
    this.pushGtmDataLayer();

    if (!this.status.isReturningCustomer) {
      this.setLoan(qaLead?.data || {});
    }

    await this.checkLeadProvider();
  },

  mounted() {
    // eslint-disable-next-line no-console
    console.info(`appVersion: ${process.env.VUE_APP_VERSION}`);
  },

  methods: {
    ...mapActions("settings", ["getSystemSettings", "setUrl", "setApiKey", "setCampaignId"]),
    ...mapActions("underwriting", [
      "setLoan",
      "setIsReturningCustomer",
      "setLoading",
      "setLeadProviderCampaignProductType",
      "setSettlementReapplyCustomerGuid",
      "setSettlementReapplyDisplayId"
    ]),

    ...mapMutations("settings", ["setCampaignInfo"]),

    initLeadSettings() {
      if (this.isSettlementReapply) {
        this.setUrl(this.settings.settlement_reapply_url);
        this.setApiKey(this.settings.settlement_reapply_provider_api_key);
        this.setCampaignId(this.settings.settlement_reapply_campaign_id);
      } else if (this.status.isReturningCustomer) {
        this.setUrl(this.settings.return_app_url);
        this.setApiKey(this.settings.return_app_provider_api_key);
        this.setCampaignId(this.settings.return_app_campaign_id);
      } else {
        this.setUrl(this.settings.generic_app_url);
        this.setApiKey(this.settings.generic_app_provider_api_key);
        this.setCampaignId(this.settings.generic_app_campaign_id);
      }
    },

    async checkLeadProvider() {
      const postUrl = `${this.apiUrl}api/provider-status/${this.apiKey}`;
      const { status, data } = await this.$axios.post(postUrl);

      if (status === 200 && data) {
        this.isLeadProviderActive = data?.isActive;

        const leadProviderCampaignProductType = data?.campaigns?.find(
          // eslint-disable-next-line eqeqeq
          (x) => x.id === this.apiCampaignId
        )?.product_type || null;

        this.setLeadProviderCampaignProductType(leadProviderCampaignProductType);
      }
    },
    notRedirectCondition(condition) {
      return condition !== "true" && process.env.VUE_APP_QA_FEATURE_ENABLED === "true";
    },

    checkSettlementReapply(condition) {
      if (this.notRedirectCondition(condition)) {
        return;
      }

      const nameSR = "settlementReapply=";
      const nameDI = "oldDisplayId=";
      const cookieArray = document.cookie.split(";");

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        const settlementReapply = {};

        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(nameSR) === 0) {
          const cookieValue = cookie.substring(nameSR.length, cookie.length);

          if (cookieValue.length) {
            this.isSettlementReapply = true;
            this.setSettlementReapplyCustomerGuid(cookieValue);
          }
        }

        if (cookie.indexOf(nameDI) === 0) {
          const cookieValue = cookie.substring(nameDI.length, cookie.length);

          if (cookieValue.length) {
            this.isSettlementReapply = true;
            settlementReapply.displayId = cookieValue;
            this.setSettlementReapplyDisplayId(cookieValue);
          }
        }
      }
    },
    checkActiveApplication(condition) {
      // we have not enabled offer redirect and this a qa test do not redirect
      if (condition !== "true" && process.env.VUE_APP_QA_FEATURE_ENABLED === "true") {
        return;
      }

      // UW create a cookie named: activeApplicationUrl when lead hit lead landing page and saved for 30 days.
      const nameEQ = "activeApplicationUrl=";
      const cookieArray = document.cookie.split(";");

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];

        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(nameEQ) === 0) {
          const redirect = cookie.substring(nameEQ.length, cookie.length);

          Bugsnag.notify(`Found an active url in cookie, redirecting to ${redirect}`);
          window.location.assign(redirect);
        }
      }
    },

    async setReturningAppData(dataUrl, isReturningCustomer = true) {
      let gotAppDataFromServer = false;

      try {
        this.setLoading(true);

        const { status, data } = await this.$axios.get(`${dataUrl}`);

        const dataId = data || {}.id || data || {}.user_from_id;
        if (status === 200 && typeof dataId !== "undefined") {
          gotAppDataFromServer = true;
          // Only update keys we have in loan data
          Object.keys(this.loan).forEach((item) => {
            if (data[item]) {
              const needsToBeUpper = [
                "incometype",
                "accounttype",
                "payfrequency",
                "paytype",
                "rentorown",
              ];
              const doNoPrefill = ["ismilitary"];

              // check the field is not in the do not prefill list
              if (!doNoPrefill.includes(item)) {
                if (needsToBeUpper.includes(item)) {
                  this.loan[item] = data[item].toUpperCase();
                } else {
                  this.loan[item] = data[item];
                }
              }
            }
          });

          // override returning customer sms consent
          this.loan.consent_status = 0;

          this.loan.customer_guid = data.guid;
          this.lastLoanInfo = data?.last_loan_info ?? {};

          let storageData = {};

          if (isReturningCustomer) {
            this.setIsReturningCustomer(true);

            storageData = {
              isReturningCustomer: this.status.isReturningCustomer,
              customer_guid: this.loan.customer_guid,
              lastLoanInfo: this.loan.lastLoanInfo,
            };
          } else if (
            data.user_from
              && data.user_from === LENDINGTREE_FROM_STRING
          ) {
            this.setIsLendingTreeCustomer(true);
            this.loan.user_from_id = data.user_from_id || 0;

            storageData = {
              isLendingTreeCustomer: this.status.isLendingTreeCustomer,
              userFromId: this.loan.user_from_id,
            };
          }

          // set fetched loanData to localstorage
          this.setStorage(storageData);
        } else {
          this.redirectBackToPortal();
        }
      } catch (e) {
        Bugsnag.notify(e);
        this.redirectBackToPortal();
      } finally {
        this.setLoading(false);
      }

      // if we did not get data from server check local storage.
      if (!gotAppDataFromServer && localStorage.getItem("loanData")) {
        this.setDataFromStorage();
      }
    },

    pushGtmDataLayer(lead = null) {
      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || [];

        let layer = {
          email: this.loan?.email || "",
          cust_id: this.loan?.customer_guid || "",
        };

        if (lead) {
          layer = {
            ...layer,
            lead_id: lead.reference,
            lead_guid: lead.redirect.substring(
              lead.redirect.lastIndexOf("/") + 1
            ),
          };
        }

        window.dataLayer.push(layer);
      }
    },

    setCampaign(params) {
      const affid = params.get("affid");
      const campaign = params.get("campaign");

      if (campaign) {
        const campaignInfo = {
          campaign: campaign.trim(),
          affid: affid.trim(),
          subid: null,
        };
        this.setCampaignInfo(campaignInfo);
      }
    },

    scroll(toError = false) {
      let top = 0;

      if (toError) {
        const domRect = document
          .querySelector(".with-errors")
          .getBoundingClientRect();
        top = domRect.top + document.documentElement.scrollTop - 230;
      }

      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    redirectBackToPortal() {
      return window.location.assign(
        `${process.env.VUE_APP_CUSTOMER_PORTAL_URL}request-funds`
      );
    },

    setStorage(data) {
      localStorage.setItem(
        "loanData",
        JSON.stringify({
          ...data,
          data: this.loan,
        })
      );
    },

    setDataFromStorage() {
      const loanData = JSON.parse(localStorage.getItem("loanData"));
      const { data } = loanData;

      if (loanData.isReturningCustomer) {
        this.status.isReturningCustomer = loanData.isReturningCustomer;
        this.loan.customer_guid = loanData.customer_guid;
      }

      if (loanData.isLendingTreeCustomer) {
        this.status.isLendingTreeCustomer = loanData.isLendingTreeCustomer;
        this.userFromId = loanData.userFromId;
      }

      // set only the available values
      Object.keys(this.loan).forEach((item) => {
        if (data[item]) {
          Vue.set(this.loan, item, data[item]);
        }
      });
    },
  },
};
</script>
