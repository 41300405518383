<template>
  <div>
    <div>
      <Loader />
      <h4 class="text-center">
        Please wait while we load your most recent application data.
      </h4>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/ui/Loader.vue";

export default {
  name: "Loading",
  components: {
    Loader
  },
};
</script>

<style scoped>

</style>
