<template>
  <div>
    <section class="wrapper borderlinetop">
      <div class="inner">
        <header class="special">
          <p><strong>IMPORTANT DISCLOSURES</strong></p>

          <FooterLinks v-if="showFooterLinks" />

          <p
            v-for="(item, index) in settings.disclosures"
            :key="index"
          >
            {{ item }}
          </p>
        </header>
      </div>
    </section>

    <footer id="footer">
      <div class="inner">
        <div class="content">
          <section class="rightpadding">
            <p>
              <a
                class="logo"
                :href="settings.link_main_website_href"
              ><img
                src="../assets/images/Loop-Fund-by-Fox-Lending.png"
                height="45"
                :alt="settings.company_long_name"
              ></a>
            </p>
          </section>
          <section class="leftborder">
            <ul class="plain">
              <li>{{ settings.company_address1 }}</li>
              <li>{{ settings.company_address2 }}</li>
              <li>
                <a :href="`mailto:${settings.company_email}`">{{ settings.company_email }}</a>
              </li>
              <li>
                <a :href="`tel:${settings.company_phone}`">{{ settings.company_phone }}</a>
              </li>
            </ul>
          </section>
        </div>
        <div class="copyright">
          &copy; {{ new Date().getFullYear() }} Fox Lending, LLC dba Loop Fund. All rights reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterLinks from "./FooterLinks.vue";

export default {
  components: {
    FooterLinks,
  },
  computed: {
    ...mapState("settings", ["settings"]),

    showFooterLinks() {
      return this.settings?.show_apply_footer_links || false;
    }
  },
};
</script>

<style></style>
