<template>
  <div
    class="v-center"
  >
    <div>
      <Loader />
      <h4 class="text-center">
        We are processing your application, please do not hit back or refresh your browser.
      </h4>
    </div>
  </div>
</template>

<script>
import Loader from "../../../components/ui/Loader.vue";

export default {
  name: "Processing",
  components: {
    Loader,
  },
};
</script>

<style scoped>

</style>
