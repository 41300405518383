<template>
  <div class="col-12-small">
    <h4 v-if="label">
      {{ label }}
    </h4>

    <slot />

    <div
      v-if="hasError"
      class="help-block with-errors"
    >
      <span class="error">
        <ul class="icons">
          <li>
            <span class="icon fa-exclamation-circle">
              <span class="label">
                <i v-if="validator.required === false">Required</i>
                <i v-else-if="validator.between === false">
                  Must be between {{ validator.$params.between.min }} and
                  {{ validator.$params.between.max }}
                </i>
                <i v-else-if="validator.phone === false">Must be a valid phone number</i>
                <i v-else-if="validator.zip === false">Must be a valid zip code</i>
                <i v-else-if="validator.lettersAndSpaces === false">Must be letters only</i>
                <i v-else-if="validator.email === false">Must be a valid email</i>
                <slot
                  v-else
                  name="error"
                />
              </span>
            </span>
          </li>
        </ul>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormGroup",
  props: {
    label: {
      type: String,
      default: ""
    },
    validator: {
      type: Object,
      required: false,
      default: () => {},
      validator($v) {
        // eslint-disable-next-line
        return $v.hasOwnProperty("$model");
      }
    }
  },
  computed: {
    hasError() {
      if (!this.validator) {
        return false;
      }
      // console.log(this.validator);

      return this.validator.$error && this.validator.$dirty;
    }
  }
};
</script>
